import { useRouter } from 'next/router';

import React, { useEffect, useState, useContext } from 'react';

// import Image from '@atoms/Image';
import { createCelloSignUpEvent, getUserEmailByUcc, userLogin, verifyLogin } from '@services/youtube-platform.service';
import {
  setAuth,
  getAuth,
  isAuthenticated,
  setBanner,
  setUniversalLoggedInCookie,
} from '@services/identity.service';
import { TeamsDataContext } from '@components/context/TeamsContext';
import useClientInfo from '@lib/hooks/useClientInfo';
import { createCheckoutSession } from '@services/billing-service';

import { isZoomApp, zoomAppBasePath } from '@zoom-app/helpers';
import ExternalLink from '@zoom-app/atoms/ExternalLink';
import { isMicrosoftTeamsApp, microsoftTeamsBasePath } from '@teams-app/helpers';
import MicrosoftTeamsExternalLink from '@teams-app/atoms/ExternalLink';
import { signupLink } from '@services/utils.service';

import Toast from '@molecules/Toast';
import Text from '@atoms/Text';
import { pushDataLayerForEvent } from '@lib/gtag';
import Button from '@atoms/new/button';
import Input from '@atoms/Input';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import yupValidator from '@lib/yup-validator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Image from 'next/image';
import useCountdownTimer from '@lib/hooks/useCountdownTimer';
import Link from 'next/link';
import { ImageSlider } from './ImageSlider';
import Loader from '@atoms/Atom/Loader';
import GoogleIcon from '@mui/icons-material/Google';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import {
  verifyGoogleAccessToken,
  verifyGoogleOneTapToken,
} from '@services/login/google';
import { usePostHog } from 'posthog-js/react';
import { useIsFirstRender } from '@uidotdev/usehooks';
import { useSearchParams } from 'next/navigation';

type CustomError = {
  type?: 'email' | 'code';
  message?: string;
};

interface ReferralEvent {
  ucc: string | null;
  referralEmail: string | null;
  referralID: string | null;
}

interface LoginProps {
  heading: string;
  buttonText: string;
  accessType: 'login' | 'signup';
  isPopup: boolean;
}
const Login = ({
  heading = 'Log In',
  buttonText = 'Get login code',
  accessType = 'login',
  isPopup = false,
}: LoginProps) => {
  const router = useRouter();
  const clientInfo = useClientInfo();
  const posthog = usePostHog();

  const [mainButtonText, setMainButtonText] = useState(buttonText);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isAppSumoUser, setIsAppSumoUser] = useState(false);
  const [appSumoPlan, setAppSumoPlan] = useState('');
  const [toastMsg, setToastMsg] = useState('');
  const [customError, setCustomError] = useState<CustomError>({});
  const { count, startTimer, isRunning, resetTimer } = useCountdownTimer();

  const { initTeam, ownTeam } = useContext(TeamsDataContext);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: router.query?.email,
    },
    resolver: yupValidator(
      yup.object().shape({
        email: yup
          .string()
          .email('Please enter a valid email.')
          .matches(/@[^.]*\./, 'Please enter a valid email.')
          .required('Please enter your email.'),
      })
    ),
  });
  const {
    register: codeRegister,
    handleSubmit: codeHandleSubmit,
    setValue,
    watch: codeWatch,
    setFocus: codeSetFocus,
    clearErrors: codeClearErrors,
    formState: { errors: codeErrors },
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        inputCode: yup
          .string()
          .min(6, 'Please enter a valid code.')
          .max(6, 'Please enter a valid code.')
          .matches(/^\d+$/, 'Please enter a valid code.')
          .required('Please enter your code.'),
      })
    ),
  });

  const email = watch('email');

  useEffect(() => {
    if (showCodeInput) {
      setShowCodeInput(false);
    }
    if (!customError) return;
    setCustomError({});
    resetTimer();
    setValue('inputCode', '');
    setMainButtonText(buttonText);
    codeClearErrors();
  }, [email]);

  useEffect(() => {
    setCustomError({});
  }, [codeWatch('inputCode')]);

  const isFirstRender = useIsFirstRender();
  const searchParams = useSearchParams();
  const [referralDetails, setReferralDetails] = useState({
    ucc: '',
    email: '',
    referralID: '',
  });

  useEffect(() => {
    if (!isFirstRender) return;

    (async () => {
      const referralID = searchParams?.get('referral') || '';

      let event: ReferralEvent = {
        ucc: null,
        referralID,
        referralEmail: null,
      };

      const ucc = searchParams?.get('ucc') || '';

      if (ucc) {
        const refferalEmail = await getUserEmailByUcc(ucc);
        const email = refferalEmail?.entity?.email;

        event.ucc = ucc;
        event.referralEmail = email;

        setReferralDetails({
          ucc,
          email,
          referralID,
        });
      }

      if (referralID || ucc) {
        posthog?.capture('Refferal Link Detected', {
          ReferrerUcc: event.ucc,
          ReferrerId: event.referralID,
          ReferrerEmail: event.referralEmail,
        });
      }
    })();
  }, []);

  const handleLoginSuccess = async (auth: any, isGoogleSignUp: boolean = false) => {
    posthog?.identify(auth.id, {
      email: auth.email,
    });
    try {
      const ucc = await window.CelloAttribution('getUcc');
      if (ucc) {
        createCelloSignUpEvent({ userId: auth.id, email: auth.email, ucc });
      }
    } catch (error) {
      console.log('Failed to fetch token ', { error });
    }
    if (auth?.isNewUser) {
      posthog?.capture('Signed Up', {
        isZoom: searchParams?.get('utm_source') === 'zoomapp',
        isReferral: !!referralDetails.ucc || !!referralDetails.referralID,
        RefererId: referralDetails.referralID,
        RefererEmail: referralDetails.email,
        ReferrerUcc: referralDetails.ucc,
        isGoogleSignUp,
      });
    }

    let path = '/';
    await setAuth(auth);
    setBanner();
    setUniversalLoggedInCookie();

    initTeam();

    if (router.query.next) {
      return await router.push(router.query.next as string);
    }
    if (isAppSumoUser) {
      path = `/appSumoPricingPlan/${appSumoPlan}`;
    }
    if (isZoomApp()) {
      path = zoomAppBasePath!;
    }
    if (isMicrosoftTeamsApp()) {
      path = microsoftTeamsBasePath!;
    }
    if (isPopup) {
      path = router.asPath;
    }
    router.push(path);
  };

  const handleGoogleLogin = async (auth: any) => {
    localStorage.setItem('user_email', auth?.email);
    localStorage.setItem('user_id', auth?.id);
    localStorage.setItem('hash', auth?.hash);
    if (auth?.isNewUser) {
      pushDataLayerForEvent('google_signup_completed', { email: auth.email });
    } else {
      pushDataLayerForEvent('google_login_success', { email: auth.email });
    }
  };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      verifyGoogleOneTapToken(credentialResponse.credential)
        .then(async (res) => {
          if (res.status) {
            handleGoogleLogin(res.entity);
            await handleLoginSuccess(res.entity, true);
          }
        })
        .catch((e) => {
          console.error('Error:', e);
        });
    },
    onError: () => {
      console.error('Login Failed');
    },
    use_fedcm_for_prompt: true,
    disabled: !!isZoomApp(),
    cancel_on_tap_outside: true,
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      verifyGoogleAccessToken(codeResponse.access_token)
        .then(async (res) => {
          if (res.status) {
            handleGoogleLogin(res.entity);
            await handleLoginSuccess(res.entity, true);
          }
        })
        .catch((e) => {
          console.error('Error:', e);
        });
    },
    onError: () => console.error('Login Failed'),
  });

  const startStripeCheckout = async (plan: any, mode: any) => {
    const checkoutDetails = await createCheckoutSession({
      newPlan: plan,
      mode,
      userId: getAuth().id,
    });
    if (checkoutDetails.status) {
      router.replace(checkoutDetails.entity.url);
    }
  };

  const stripeCheckout = async (plan: any) => {
    if (ownTeam?.billing_account?.purchase_plan?.name === 'Free Plan') {
      const mode = plan.toLowerCase().includes('event')
        ? 'payment'
        : 'subscription';
      await startStripeCheckout(plan, mode);
    } else {
      window.location.replace('/pricing');
    }
  };

  useEffect(() => {
    const auth = getAuth();

    if (isAuthenticated(auth)) {
      (async () => {
        await initTeam();
        if (
          router.query?.plan &&
          ownTeam?.billing_account?.purchase_plan?.name
        ) {
          await stripeCheckout(router.query.plan);
        } else if (isZoomApp()) {
          router.push(zoomAppBasePath!);
        }
        if (isMicrosoftTeamsApp()) {
          router.push(microsoftTeamsBasePath!);
        }
      })();
    }
    // Just making sure that zoom users don't end up in normal login page.
    if (isZoomApp()) {
      const matches = router.pathname.match(/(^\/login|^\/signup)/);
      if (matches && matches.length > 0) {
        router.push(`/zoom-app${matches[0]}`);
      }
    }
    if (isMicrosoftTeamsApp()) {
      const matches = router.pathname.match(/(^\/login|^\/signup)/);
      if (matches && matches.length > 0) {
        router.push(`/teams-app${matches[0]}`);
      }
    }
  }, []);

  useEffect(() => {
    if (router.query?.email) {
      if (router.query.tier) {
        setIsAppSumoUser(true);
        setAppSumoPlan(router.query.tier as string);
      }
    }
  }, [router.query?.email]);

  const handle429 = (e: Error) => {
    if (e.message.match('429')) {
      setToastMsg('Too many requests, please try again after sometime.');
    }
  };

  const getLoginCode = async (
    { email: emailInput }: { email: string },
    onSuccess?: () => void
  ) => {
    const email = emailInput.toLowerCase();
    try {
      const res = await userLogin({ email, accessType });
      if (accessType === 'signup') {
        pushDataLayerForEvent('signup_started', { email }, false);
      } else {
        pushDataLayerForEvent('login_started', { email }, false);
      }

      if (res.message[0] === 'User already exists.') {
        setFocus('email');
        setCustomError({
          type: 'email',
          message: 'That account exists.',
        });
        return;
      }
      if (res.message[0] === 'No Account Exist') {
        setFocus('email');

        setCustomError({
          type: 'email',
          message: 'No account exists with that email.',
        });
        return;
      }
      if (!res.status) {
        setFocus('email');
        const defaultErrMsg = 'Something went wrong. Please try again.';
        if (Array.isArray(res.message)) {
          setToastMsg(res.message?.[0] || defaultErrMsg);
        } else setToastMsg(res.message || defaultErrMsg);
        return;
      }
      setShowCodeInput(true);
      setMainButtonText('Log In');
      onSuccess?.();
    } catch (e: any) {
      handle429(e);
    }
  };

  const verifyLoginCode = async ({ inputCode }: { inputCode: string }) => {
    setLoader(true);
    const email = getValues('email').toLowerCase();

    let path = '/';

    try {
      const res = await verifyLogin({
        email,
        inputCode,
        accessType,
        clientInfo,
      });
      if (res.status) {
        localStorage.setItem('user_email', email);
        localStorage.setItem('user_id', res.entity[0]?.id);
        localStorage.setItem('hash', res.entity[0]?.hash);
        if (accessType === 'signup') {
          // @ts-expect-error will check if variable is defined
          if (window.fpr) {
            // @ts-expect-error variable is defined
            window.fpr('referral', { email });
          }
          pushDataLayerForEvent('signup_completed', { email }, false);
          // @ts-expect-error will check if variable is defined
          if (window.lintrk) {
            // @ts-expect-error  variable is defined
            window.lintrk('track', { conversion_id: 10938372 });
          }
        } else {
          pushDataLayerForEvent('login_success', { email }, false);
        }

        await handleLoginSuccess(res.entity[0]);
      } else {
        codeSetFocus('inputCode');
        setCustomError({
          type: 'code',
          message: 'Code is invalid or expired. Try requesting a new code.',
        });
        setLoader(false);
      }
    } catch (e: any) {
      handle429(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (showCodeInput) {
      codeSetFocus('inputCode');
    }
  }, [showCodeInput]);

  return (
    <div className="bg-white flex size-full justify-center text-grey-900 overflow-y-auto">
      <div className="flex flex-1 static h-full max-w-160">
        {toastMsg && (
        // @ts-expect-error not tsx
          <Toast
            severity="error"
            body={toastMsg}
            position="absolute"
            onClose={() => setToastMsg('')}
            autoCloseInterval={5000}
          />
        )}
        <div
          className={`flex flex-1 z-10 ${isZoomApp() && 'tablet:p-12 p-6 md:px-24 md:py-9'} ${!isPopup && !isZoomApp() && 'p-6 tablet:p-12 md:p-16 lg:px-24 lg:py-9'}  overflow-auto`}
        >
          <div
            className={`flex flex-1 flex-col relative ${!isPopup && 'gap-6'}`}
          >
            {isZoomApp() && (
              <div>
                <Button
                  kind="icon-only"
                  size="sm"
                  className="inline-block bg-grey-100 mr-2 rounded-[5px]"
                  onClick={() => router.back()}
                >
                  <ArrowBackIcon fontSize="small" />
                </Button>
                <div className="inline-block">
                  <Image
                    width={144}
                    height={144}
                    alt="streamalive logo"
                    src={'/images/logos/streamalive.svg'}
                    className="w-36 inline-block"
                  />
                </div>
              </div>
            )}
            {!isPopup && (!isZoomApp() || !isMicrosoftTeamsApp()) ? (
              <Image
                width={144}
                height={144}
                alt="streamalive logo"
                src={'/images/logos/streamalive.svg'}
                className="w-36"
              />
            ) : (
              <div className="mt-2" />
            )}
            {loader && <Loader className="my-auto" />}
            {!loader && (
              <>
                <div className="flex-1 place-self-stretch"></div>
                <div className="flex flex-col  justify-center">
                  <h4
                    className={`${isPopup ? 'text-xl mb-6' : 'text-4xl mb-6'} font-semibold`}
                  >
                    {heading}
                  </h4>

                  {!isZoomApp() && (
                    <div>
                      <Button
                        className="w-full"
                        startContent={<GoogleIcon />}
                        onClick={() => googleLogin()}
                      >
                        Continue with Google
                      </Button>
                      <div className="flex items-center my-6">
                        <div className="flex-1 border border-grey-200"></div>
                        <p className="text-grey-800 text-center font-normal text-sm mx-2">
                          or
                        </p>
                        <div className="flex-1 border border-grey-200"></div>
                      </div>
                    </div>
                  )}

                  {(errors?.email ||
                    customError?.message ||
                    codeErrors?.inputCode) && (
                    <div className="mb-4 bg-red-50 border border-pure-red-600 px-4 py-3.5 rounded-md flex gap-[15px] items-center">
                      <InfoOutlinedIcon
                        className="text-red-600"
                        fontSize="small"
                      />
                      <span className="text-sm">
                        {(errors?.email?.message as string) ||
                          (codeErrors?.inputCode?.message as string) ||
                          customError.message}
                      </span>
                    </div>
                  )}

                  <form>
                    <label className="text-sm font-semibold">Email</label>
                    <Input
                      {...register('email')}
                      disabled={isAppSumoUser}
                      isInvalid={
                        customError.type === 'email' || !!errors?.email
                      }
                      className="mt-2 w-full"
                      size="lg"
                    />

                    {showCodeInput && (
                      <div className="mt-4">
                        <label className="text-sm font-semibold">
                          {accessType === 'login'
                            ? 'Login code'
                            : 'Signup code'}
                        </label>
                        <Input
                          {...codeRegister('inputCode')}
                          maxLength={6}
                          placeholder={`Paste ${accessType} code`}
                          disabled={isAppSumoUser}
                          isInvalid={
                            customError.type === 'code' ||
                            !!codeErrors?.inputCode
                          }
                          className="mt-2 w-full"
                          size="lg"
                        />
                        <p className="text-sm mt-2 text-grey-700">
                          We sent a {accessType} code to your inbox · &nbsp;
                          {isRunning ? (
                            `${count}s`
                          ) : (
                            <button
                              type="button"
                              className="text-blue-600 font-semibold cursor-pointer"
                              onClick={() => {
                                getLoginCode(
                                  { email: getValues('email') },
                                  () => startTimer(30)
                                );
                              }}
                            >
                              Resend
                            </button>
                          )}
                        </p>
                      </div>
                    )}

                    <Button
                      id="login"
                      type="submit"
                      onClick={
                        showCodeInput
                          ? codeHandleSubmit(verifyLoginCode)
                          : handleSubmit((data) => getLoginCode(data))
                      }
                      isLoading={loader}
                      size="md"
                      kind="secondary"
                      className="w-full mt-4"
                    >
                      {showCodeInput
                        ? accessType === 'login'
                          ? 'Log in'
                          : 'Create free account'
                        : mainButtonText}
                    </Button>

                    {accessType === 'login' && (
                      <p className="text-sm my-6">
                        <span>New to streamAlive? </span>
                        {isZoomApp() ? (
                          <ExternalLink
                            href={signupLink('zoomapp')}
                            className="text-blue-600 font-semibold cursor-pointer"
                          >
                            Sign up
                          </ExternalLink>
                        ) : (
                          <a
                            onClick={() => {
                              if (isPopup) {
                                window.open('/signup', '_blank');
                                return;
                              }

                              router.push({
                                pathname: '/signup',
                                query: router.query,
                              });
                            }}
                            className="text-blue-600 font-semibold cursor-pointer"
                          >
                            Sign up
                          </a>
                        )}
                      </p>
                    )}

                    {accessType === 'signup' && (
                      // @ts-expect-error not tsx
                      <Text className={`text-sm ${isPopup ? 'mt-6' : 'mt-5'}`}>
                        <span>Already have an account? </span>

                        {
                          <a
                            onClick={() => router.push({ pathname: (isZoomApp() ? '/zoom-app/login' : isMicrosoftTeamsApp() ? '/teams-app/login' : '/login'), query: router.query })}
                            className="text-blue-600 text-sm font-semibold cursor-pointer"
                          >
                            Log in
                          </a>
                        }
                      </Text>
                    )}
                  </form>
                  {accessType === 'signup' && (
                    <p
                      className={`${isPopup ? 'pt-6 mt-6' : 'pt-8 my-8'} border-t border-grey-100 text-grey-700 text-sm`}
                    >
                      By signing up, you agree to the
                      <Link
                        target="_blank"
                        href="https://www.streamalive.com/terms-of-service"
                        className="text-grey-800 font-medium"
                      >
                        {' '}
                        Terms of service{' '}
                      </Link>
                      and
                      <Link
                        target="_blank"
                        href="https://www.streamalive.com/privacy"
                        className="text-grey-800 font-medium"
                      >
                        {' '}
                        Privacy policy
                      </Link>
                    </p>
                  )}
                </div>
                <div className="flex-1 place-self-stretch"></div>
              </>
            )}
          </div>
        </div>
      </div>

      {!isPopup && <Banner isSignup={accessType === 'signup'} />}
    </div>
  );
};

interface BannerProps {
  isSignup?: boolean;
}

const Banner = ({ isSignup }: BannerProps) => {
  return (
    <div className="hidden flex-1 login-gradient p-[40px] lg:flex flex-col justify-center items-center">
      {isSignup && (
        <div className="text-left w-full max-w-[680px] mb-12">
          <p className="text-white text-2xl font-semibold mb-4">
            Why sign up?
          </p>

          <ul className="flex flex-col text-grey-300 text-lg font-normal gap-2 list-disc ml-5">
            <li>Transform audiences into participants.</li>
            <li>Use with all your favorite platforms.</li>
            <li>Get useful analytics after your session.</li>
          </ul>
        </div>
      )}

      <div
        className={`max-w-[680px] flex items-center justify-center flex-col ${isSignup ? 'bg-[#141414] py-9 px-6 rounded-[16px] border border-grey-900' : ''}`}
      >
        <p className={`font-normal text-white mb-14 leading-[42px] text-center ${isSignup ? 'text-lg max-w-[400px] mb-8' : 'text-2.5xl'}`}>
          Used by <span className="font-semibold">Academia</span>,{' '}
          <span className="font-semibold">Businesses</span> and{' '}
          <br />
          <span className="font-semibold">Creators</span> to elevate live
          sessions
        </p>
        <ImageSlider />
      </div>
    </div>
  );
};

export default Login;
