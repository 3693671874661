import type React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Avatar1 from '../../public/images/login/avatar-1.png';
import Avatar2 from '../../public/images/login/avatar-2.png';
import Avatar3 from '../../public/images/login/avatar-3.png';
import Avatar4 from '../../public/images/login/avatar-4.png';
import Avatar5 from '../../public/images/login/avatar-5.png';
import Avatar6 from '../../public/images/login/avatar-6.png';
import Avatar7 from '../../public/images/login/avatar-7.png';
import Avatar8 from '../../public/images/login/avatar-8.png';
import Avatar9 from '../../public/images/login/avatar-9.png';
import Avatar10 from '../../public/images/login/avatar-10.png';

import Brand1 from '../../public/images/login/brand-1.png';
import Brand2 from '../../public/images/login/brand-2.png';
import Brand3 from '../../public/images/login/brand-3.png';
import Brand4 from '../../public/images/login/brand-4.png';
import Brand5 from '../../public/images/login/brand-5.png';
import Brand6 from '../../public/images/login/brand-6.png';
import Brand7 from '../../public/images/login/brand-7.png';
import Brand8 from '../../public/images/login/brand-8.png';
import Brand9 from '../../public/images/login/brand-9.png';
import Brand10 from '../../public/images/login/brand-10.png';
import Brand11 from '../../public/images/login/brand-11.png';
import Brand12 from '../../public/images/login/brand-12.png';
import Brand13 from '../../public/images/login/brand-13.png';
import Brand14 from '../../public/images/login/brand-14.png';
import Brand15 from '../../public/images/login/brand-15.png';
import Brand16 from '../../public/images/login/brand-16.png';
import Brand17 from '../../public/images/login/brand-17.png';
import Brand18 from '../../public/images/login/brand-18.png';

const brandLogos = [
  {
    id: '1',
    src: Brand1,
    alt: 'brand1'
  },
  {
    id: '2',
    src: Brand2,
    alt: 'brand2'
  },
  {
    id: '3',
    src: Brand3,
    alt: 'brand3'
  },
  {
    id: '4',
    src: Brand4,
    alt: 'brand4'
  },
  {
    id: '5',
    src: Brand5,
    alt: 'brand5'
  },
  {
    id: '6',
    src: Brand6,
    alt: 'brand6'
  },
  {
    id: '7',
    src: Brand7,
    alt: 'brand7'
  },
  {
    id: '8',
    src: Brand8,
    alt: 'brand8'
  },
  {
    id: '9',
    src: Brand9,
    alt: 'brand9'
  },
  {
    id: '10',
    src: Brand10,
    alt: 'brand10'
  },
  {
    id: '11',
    src: Brand11,
    alt: 'brand11'
  },
  {
    id: '12',
    src: Brand12,
    alt: 'brand12'
  },
  {
    id: '13',
    src: Brand13,
    alt: 'brand13'
  },
  {
    id: '14',
    src: Brand14,
    alt: 'brand14'
  },
  {
    id: '15',
    src: Brand15,
    alt: 'brand15'
  },
  {
    id: '16',
    src: Brand16,
    alt: 'brand16'
  },
  {
    id: '17',
    src: Brand17,
    alt: 'brand17'
  },
  {
    id: '18',
    src: Brand18,
    alt: 'brand18'
  }
];

const avatarLogos = [
  {
    id: 'avatar1',
    src: Avatar1,
    alt: 'avatar1'
  },
  {
    id: 'avatar2',
    src: Avatar2,
    alt: 'avatar2'
  },
  {
    id: 'avatar3',
    src: Avatar3,
    alt: 'avatar3'
  },
  {
    id: 'avatar4',
    src: Avatar4,
    alt: 'avatar4'
  },
  {
    id: 'avatar5',
    src: Avatar5,
    alt: 'avatar5'
  },
  {
    id: 'avatar6',
    src: Avatar6,
    alt: 'avatar6'
  },
  {
    id: 'avatar7',
    src: Avatar7,
    alt: 'avatar7',
  },
  {
    id: 'avatar8',
    src: Avatar8,
    alt: 'avatar8'
  },
  {
    id: 'avatar9',
    src: Avatar9,
    alt: 'avatar9'
  },
  {
    id: 'avatar10',
    src: Avatar10,
    alt: 'avatar10'
  }
];

export const ImageSlider = () => {
  return (
    <div className='w-full flex flex-col gap-8'>
      <Slider
        dots={false}
        infinite={true}
        speed={6500}
        autoplaySpeed={0}
        slidesToShow={4}
        slidesToScroll={1}
        autoplay={true}
        arrows={false}
        cssEase='linear'
        rtl={true}
      >
        {brandLogos.map((img, index) => (
          <img
            key={index}
            src={img.src.src}
            alt={img.alt}
            className='object-contain h-[32px]'
          />
        ))}
      </Slider>
      <Slider
        rtl={false}
        dots={false}
        infinite={true}
        speed={6500}
        autoplaySpeed={0}
        slidesToShow={4}
        slidesToScroll={1}
        autoplay={true}
        arrows={false}
        cssEase='linear'
      >
        {avatarLogos.map((img, index) => (
          <img
            key={index}
            src={img.src.src}
            alt={img.alt}
            className='object-contain h-[52px]'
          />
        ))}
      </Slider>
    </div>
  );
};
